import * as React from "react";
import styled from "styled-components";
import { respondTo } from "../_respondTo";

export const PageWrapper = styled.main`
  width: 100%;
  min-height: 100vh;
`;

export const ModalWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

export const TextWrapper = styled.div`
  max-width: min(960px, 90vw);

  background-color: rgba(2, 15, 115, 0.7);
  color: white;
  margin: 20px auto;
  font-family: sans-serif;
  padding: 20px;

  line-height: 1.6;

  ${respondTo.xsmall`
    text-align: justify;

`}

  a {
    color: white;
  }
`;

export const ScreenWrapper = styled.div`
  width: 100%;
  min-height: 100vh;

  &.cliparrow {
    clip-path: polygon(
      0% 0%,
      100% 0%,
      100% 95%,
      55% 95%,
      50% 100%,
      45% 95%,
      0% 95%
    );
  }
`;
