import {css} from "styled-components";

const breakpoints ={
    xxsmall: "360px",
    xsmall:"480px",
    small:"736px",
    medium:"980px",
    large:"1280px",
    xlarge:"1680px"
}

export const respondTo = Object.keys(breakpoints).reduce(
    (accumulator, label)=>{
        accumulator[label] = (...args)=>css`
        @media (min-width: ${breakpoints[label]}){
            ${css(...args)};
        }
        `;
        return accumulator;
    },
    {}
);

